import type { FC } from "react";
import type { IModuleImage } from "@contentful-api/types/contentful";
import { NextImage } from "@ui/components/media-and-icons/image/NextImage";
import type { IModuleRenderedProps } from "@lib/contentful/modules-renderer/ModuleCreator";

interface IMediaProps extends IModuleRenderedProps<IModuleImage> {}

export const Image: FC<IMediaProps> = ({ module }) => {
    const image = module?.fields?.image;

    return (
        <NextImage
            key={image?.sys?.id}
            src={image?.fields?.file?.url}
            sizes={["100vw", "50vw", "50vw"]}
            fill={true}
            objectFit={"cover"}
        />
    );
};
