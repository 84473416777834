import { Link } from "@components/cms/link/Link";
import { renderRichTextToComponent } from "@components/cms/rich-text-renderer/renderRichTextToComponent";
import type { IModuleCardGeneric } from "@contentful-api/types/contentful";
import type { IModuleRenderedProps } from "@lib/contentful/modules-renderer/ModuleCreator";
import { Button } from "@ui/components/forms/button/Button";
import { Box } from "@ui/components/layout/box/Box";
import { Card } from "@ui/components/layout/card/Card";
import { ECCOIcon } from "@ui/components/media-and-icons/ecco-icon/ECCOIcon";
import type { FC } from "react";

interface CardGenericProps extends IModuleRenderedProps<IModuleCardGeneric> {}

export const CardGeneric: FC<CardGenericProps> = ({ module, isLeftColumn }) => {
    const text = module?.fields?.text;
    const linkButton = module?.fields?.linkButton;
    const icon = module?.fields?.icon;
    const mergeWithPrevious = module?.fields?.mergeWithPrevious;
    const isPrintPDFLink = module?.fields?.printPdfButton === "yes";
    const printPdfLabel = module?.fields?.printPdfLabel;

    return (
        <Card mergeWithPreviousCard={mergeWithPrevious} id="card-generic">
            {icon && <ECCOIcon name={icon} aria-label={icon} size="xl" />}
            <Box
                sx={{
                    "& p": {
                        color: "black",
                        mb: isPrintPDFLink && 0,
                    },
                    "& p:empty::before": {
                        content: "'\\A'",
                        whiteSpace: "pre",
                    },
                    span: {
                        display: isLeftColumn ? "inline" : "block",
                        fontWeight: "semibold",
                        color: "black",
                        mb: isPrintPDFLink ? 0 : 2,
                    },
                }}
                fontWeight="semibold"
            >
                {renderRichTextToComponent(text)}
            </Box>
            {linkButton && (
                <Button
                    rightIcon={<ECCOIcon name="arrow-right" />}
                    as={Link}
                    link={linkButton}
                    variant={"outline"}
                    w={"full"}
                    h={"full"}
                    sx={{
                        textDecoration: "none",
                        "&:hover": {
                            textDecoration: "none",
                        },
                    }}
                >
                    <span>{linkButton?.fields?.label}</span>
                </Button>
            )}
            {isPrintPDFLink && (
                <Button
                    rightIcon={!isPrintPDFLink && <ECCOIcon name="arrow-right" />}
                    variant={isPrintPDFLink ? "standard" : "outline"}
                    w={isPrintPDFLink ? "fit-content" : "full"}
                    h={isPrintPDFLink ? "fit-content" : "full"}
                    onClick={isPrintPDFLink ? () => window.print() : undefined}
                    sx={{
                        textDecoration: "none",
                        "&:hover": {
                            textDecoration: "none",
                        },
                    }}
                >
                    <span>{printPdfLabel}</span>
                </Button>
            )}
        </Card>
    );
};
