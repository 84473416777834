import type { IModuleSpacer } from "@contentful-api/types/contentful";
import type { IModuleRenderedProps } from "@lib/contentful/modules-renderer/ModuleCreator";
import type { SpacerProps as _SpacerProps } from "@ui/components/layout/spacer/Spacer";
import { Spacer as _Spacer } from "@ui/components/layout/spacer/Spacer";
import type { FC } from "react";

interface ISpacerProps extends IModuleRenderedProps<IModuleSpacer>, _SpacerProps {}

export const Spacer: FC<ISpacerProps> = ({
    module,
    isLeftColumn,
    isRightColumn,
    authorizationInvitation,
    ...rest
}) => {
    const size = module.fields.size;
    return <_Spacer size={size} {...rest} />;
};
