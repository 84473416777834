import type { FC } from "react";
import {
    Accordion as _Accordion,
    AccordionButton,
    AccordionItem,
    AccordionPanel,
    AccordionIcon,
} from "@ui/components/disclosure/accordion/Accordion";
import { Text } from "@ui/components/content/text/Text";
import type { IModuleAccordion } from "@contentful-api/types/contentful";
import {
    accordionItemStyles,
    accordionPanelStyles,
    readMoreButtonStyles,
} from "@components/cms/accordion/AccordionStyles";
import { H } from "@ui/components/content/heading/A11yHeading";
import { renderRichTextToComponent } from "@components/cms/rich-text-renderer/renderRichTextToComponent";
import { Box } from "@ui/components/layout/box/Box";
import { space } from "@ui/design-tokens";
import type { IModuleRenderedProps } from "@lib/contentful/modules-renderer/ModuleCreator";

export interface IAccordionProps extends IModuleRenderedProps<IModuleAccordion> {}

export const Accordion: FC<IAccordionProps> = ({ module, isRightColumn }) => {
    const header = module?.fields?.header;
    const accordionItems = module?.fields?.accordionItems;

    return (
        <>
            {isRightColumn ? (
                <Text textAlign="start" fontWeight="semibold">
                    {header}
                </Text>
            ) : (
                <H size="h5" textAlign="start" mb={[4, 4, 6]}>
                    {header}
                </H>
            )}
            <_Accordion allowToggle w="full">
                {accordionItems?.map?.((item) => (
                    <AccordionItem
                        key={`faq-${item?.fields?.heading}`}
                        w="100%"
                        borderTopWidth={space["0.2"]}
                        borderColor="gray.100"
                        sx={accordionItemStyles}
                    >
                        <AccordionButton w={"100%"} borderRadius={0} sx={readMoreButtonStyles}>
                            <Text
                                fontSize={
                                    isRightColumn
                                        ? [
                                              "mobileBodyTextSmall",
                                              "mobileBodyTextSmall",
                                              "desktopBodyTextSmall",
                                          ]
                                        : [
                                              "mobileBodyTextNormal",
                                              "mobileBodyTextNormal",
                                              "desktopBodyTextLarge",
                                          ]
                                }
                            >
                                {item?.fields?.heading}
                            </Text>
                            <AccordionIcon data-testid="arrow-icon" />
                        </AccordionButton>
                        <AccordionPanel sx={accordionPanelStyles}>
                            <Box
                                fontSize="desktopBodyTextSmall"
                                sx={{
                                    "& ul li": {
                                        fontSize: "desktopBodyTextSmall",
                                        "& p": {
                                            mb: 0.5,
                                        },
                                    },
                                }}
                            >
                                {renderRichTextToComponent(item?.fields?.text)}
                            </Box>
                        </AccordionPanel>
                    </AccordionItem>
                ))}
            </_Accordion>
        </>
    );
};
