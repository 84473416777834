import { isProduction } from "@config/site/site-api-settings";
import type { Document } from "@contentful/rich-text-types";
import { ModuleCreator } from "@lib/contentful/modules-renderer/ModuleCreator";
import { ModuleError } from "@lib/contentful/modules-renderer/ModuleError";
import type { Module as ModuleType } from "@lib/contentful/modules-renderer/ModuleType";
import { modulesMap } from "@lib/contentful/modules-renderer/modules-map";
import { Text } from "@ui/components/content/text/Text";
import { Card } from "@ui/components/layout/card/Card";
import type { FC } from "react";
import { cloneElement } from "react";
import { useContentfulLiveUpdates } from "@contentful/live-preview/react";
import { useRouter } from "next/router";
import type { IEntry } from "@contentful-api/types/contentful";

interface IModuleInvalid {
    type: string;
}

const ModuleInvalid: FC<IModuleInvalid> = ({ type }) => {
    if (isProduction) return null;

    return (
        <ModuleError>
            <Text as="pre">
                Missing Contentful Data for Contentful Module:{" "}
                <Text as="code" fontWeight="bold">
                    {type}
                </Text>{" "}
                (missing sys.id and module fields)
            </Text>
        </ModuleError>
    );
};

interface IModule {
    index: number;
    module: ModuleType;
    isLeftColumn?: boolean;
    isRightColumn?: boolean;
    authorizationInvitation?: Document;
}
export const GetLiveUpdates = (data: ModuleType | IEntry) => {
    return useContentfulLiveUpdates(data);
};

export const Module: FC<IModule> = ({ module: moduleData, index, ...rest }) => {
    const type = moduleData.sys?.contentType?.sys?.id;
    const { isPreview } = useRouter();
    const Component = modulesMap[type];

    return (
        <ModuleCreator
            module={isPreview ? (GetLiveUpdates(moduleData) as ModuleType) : moduleData}
            index={index}
            type={type}
            component={Component}
            {...rest}
        />
    );
};

const isAlreadyCard = (type: ModuleType["sys"]["contentType"]["sys"]["id"]) => {
    const cardModules = ["moduleCardGeneric", "moduleCardLinkCollection"];

    return cardModules.includes(type);
};

interface ModulesRendererProps {
    modules: ModuleType[];
    cardSize?: "sm" | "md";
    isLeftColumn?: boolean;
    isRightColumn?: boolean;
    wrapInsideCard?: boolean;
    authorizationInvitation?: Document;
}

export const ModulesRenderer: FC<ModulesRendererProps> = ({
    modules,
    cardSize,
    isLeftColumn = false,
    isRightColumn = false,
    wrapInsideCard = false,
    authorizationInvitation,
    ...rest
}) => {
    return (
        <>
            {modules?.map((mod, i) => {
                const type = mod.sys?.contentType?.sys?.id;

                const moduleData = mod.sys ? (
                    <Module
                        module={mod}
                        key={`${mod.sys.id} - ${i}`}
                        index={i}
                        isLeftColumn={isLeftColumn}
                        isRightColumn={isRightColumn}
                        authorizationInvitation={authorizationInvitation}
                        {...rest}
                    />
                ) : (
                    <ModuleInvalid key={i} type={type} />
                );

                if (!wrapInsideCard) {
                    return moduleData;
                }

                return isAlreadyCard(type) ? (
                    cloneElement(moduleData, {
                        size: cardSize,
                    })
                ) : (
                    <Card isLeftColumn={isLeftColumn} key={`${mod.sys.id} - ${i}`} size={cardSize}>
                        {moduleData}
                    </Card>
                );
            })}
        </>
    );
};
