import { H } from "@ui/components/content/heading/A11yHeading";
import { motion } from "framer-motion";
import type { FC } from "react";

interface IMotion {
    title?: string;
    handleAnimationComplete?: () => void;
    sx?: any;
    isFirstOnPage?: boolean;
}
export const MotionTitle: FC<IMotion> = ({
    title,
    handleAnimationComplete,
    sx,
    isFirstOnPage,
    ...rest
}) => {
    const titleArray = title.split("");

    return (
        <H
            sx={sx}
            size="h1"
            color="white"
            fontWeight="bold"
            fontSize={["mobileH1", "desktopH1", "desktopH1"]}
            aria-label={title}
            level={isFirstOnPage ? "h1" : null}
            suppressHydrationWarning
            {...rest}
        >
            {titleArray.map((char: string, index: number) => (
                <motion.span
                    key={index}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.1 }}
                    onAnimationComplete={
                        index === titleArray.length - 1 ? handleAnimationComplete : null
                    }
                >
                    {char}
                </motion.span>
            ))}
        </H>
    );
};
