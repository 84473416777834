import type {
    AccordionButtonProps,
    AccordionItemProps,
    AccordionPanelProps,
} from "@ui/components/disclosure/accordion/Accordion";

export const accordionItemStyles: AccordionItemProps["sx"] = {
    backgroundColor: "transparent",
    borderRadius: 0,
};
export const accordionPanelStyles: AccordionPanelProps["sx"] = {
    paddingLeft: 0,
    paddingBottom: 4,
};
export const readMoreButtonStyles: AccordionButtonProps["sx"] = {
    textAlign: "left",
    fontSize: ["mobileBodyTextNormal", "mobileBodyTextNormal", "desktopBodyTextLarge"],
    fontWeight: "semibold",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "transparent",
    color: "black",
    py: 4,
    px: 0,
    _expanded: {
        backgroundColor: "transparent",
    },
    _hover: { background: "transparent" },
    "& > svg": {
        width: "1.5rem",
        height: "1.5rem",
    },
};
