import type { BoxProps as _BoxProps } from "../box/Box";
import { Box as _Box } from "../box/Box";

import { forwardRef, useStyleConfig } from "@chakra-ui/react";

export interface SpacerProps extends _BoxProps {
    size: "sm" | "md" | "lg";
}

export const Spacer = forwardRef<SpacerProps, "div">(({ size = "sm", ...props }, ref) => {
    const styles = useStyleConfig("Spacer", { size });
    return <_Box __css={styles} ref={ref} {...props} />;
});
