import { isProduction } from "@config/site/site-api-settings";
import { ModuleError } from "@lib/contentful/modules-renderer/ModuleError";
import { Text } from "@ui/components/content/text/Text";

export const ModuleNotFound = ({ type }: { type: string }) => {
    if (isProduction) return null;

    return (
        <ModuleError>
            <Text as="pre">
                Missing React implementation of Contentful Module:{" "}
                <Text as="code" fontWeight="bold">
                    {type}
                </Text>
            </Text>
        </ModuleError>
    );
};
