import type { FC } from "react";
import type { IModuleText } from "@contentful-api/types/contentful";
import { renderRichTextToComponent } from "@components/cms/rich-text-renderer/renderRichTextToComponent";
import type { BoxProps } from "@ui/components/layout/box/Box";
import { Box } from "@ui/components/layout/box/Box";
import { Flex } from "@ui/components/layout/flex/Flex";
import type { IModuleRenderedProps } from "@lib/contentful/modules-renderer/ModuleCreator";

const widthMap: Record<IModuleText["fields"]["textWidth"], BoxProps["width"]> = {
    small: ["full", "full", "full", "full", "60%"],
    medium: ["full", "full", "full", "full", "70%"],
    "full width": "full",
};

interface ITextProps extends IModuleRenderedProps<IModuleText> {}

export const Text: FC<ITextProps> = ({ module, isLeftColumn }) => {
    const text = module?.fields?.text;
    const textAlign = module?.fields?.textAlign || "center";
    const textWidth = module?.fields?.textWidth || "full width";
    // We rely in the Contentful entry ket key and isLeftColumn prop to determine if the page is the privacy policy page
    // It's something won't change that often, is needed for pass policies and won't change.
    const isPrivacyPolicyPage = module?.fields?.key === "privacyPolicy" && isLeftColumn;
    return (
        <Flex
            w="full"
            flexDirection="column"
            textAlign={textAlign}
            gap={4}
            alignItems="center"
            data-contentful-entry-id={module?.sys?.id}
            data-contentful-field-id={"text"}
        >
            <Box pr={{ base: "inherit", xl: isPrivacyPolicyPage && "36" }} w={widthMap[textWidth]}>
                {renderRichTextToComponent(text)}
            </Box>
        </Flex>
    );
};
