import { Link } from "@components/cms/link/Link";
import VideoStyles from "@components/cms/video/video.module.css";
import type { IModuleHero } from "@contentful-api/types/contentful";
import { AnalyticsClient } from "@lib/analytics/AnalyticsClient";
import type { IModuleRenderedProps } from "@lib/contentful/modules-renderer/ModuleCreator";
import { Box } from "@ui/components/layout/box/Box";
import type { FlexProps } from "@ui/components/layout/flex/Flex";
import { Flex } from "@ui/components/layout/flex/Flex";
import { ECCOIcon } from "@ui/components/media-and-icons/ecco-icon/ECCOIcon";
import { NextImage } from "@ui/components/media-and-icons/image/NextImage";
import { useContrastText } from "@ui/hooks/useContrastText";
import { isEmpty } from "lodash-es";
import dynamic from "next/dynamic";
import type { FC } from "react";
import { useState } from "react";
import { useInView } from "react-intersection-observer";
import { space } from "ui/src/design-tokens";
import { renderRichTextToComponent } from "../rich-text-renderer/renderRichTextToComponent";
import { styles } from "./HeroStyle";
import { MotionTitle } from "./MotionTitle";
import { Button } from "@ui/components/forms/button/Button";

const headingDirectionMap: Record<
    IHeroProps["module"]["fields"]["headingPosition"],
    FlexProps["flexDirection"]
> = {
    left: ["column", "column", "column", "row-reverse"],
    right: ["column", "column", "column", "row"],
};

interface IHeroProps extends IModuleRenderedProps<IModuleHero> {
    slideIndex?: number; // Position of the Hero when used in HeroSlider
    hasNavigation?: boolean;
    height?: string | string[];
}

const ReactPlayer = dynamic(() => import("react-player/lazy"), { ssr: false });
const MotionDiv = dynamic(() => import("framer-motion").then((mod) => mod.motion.div));

export const Hero: FC<IHeroProps> = ({
    module,
    index,
    slideIndex = 0,
    height = ["113", "100vh", "100vh", "100vh"],
    hasNavigation,
}) => {
    const [isVideoPlaying, setIsVideoPlaying] = useState<boolean>(false);

    const {
        backgroundColor,
        backgroundVideo,
        headingPosition = "left",
        imageSize = "large",
        callToActions,
        description,
        image,
        imageMobile,
        title,
        legalText,
    } = module.fields;

    const id = module?.sys?.id;
    const hashedId = backgroundVideo?.items?.[0]?.hashed_id;
    const isFullSizeImage = imageSize === "full width";
    const shouldRenderHeading = !isEmpty(title) || !isEmpty(description) || !isEmpty(callToActions);
    const shouldRenderVideo = !isEmpty(backgroundVideo) && !isEmpty(hashedId);
    const shouldRenderImage = !shouldRenderVideo && !isEmpty(image);
    const { isBlack } = useContrastText(backgroundColor?.value);
    const isFirstOnPage = index === 0 && slideIndex === 0; // Only the first image on the page should be eagerly loaded

    const { ref } = useInView({
        threshold: 0.5,
        onChange: (inView) => {
            if (inView && id) {
                setTimeout(
                    () =>
                        AnalyticsClient.viewPromotion({
                            image: (image?.fields?.file?.url ??
                                backgroundVideo?.items?.[0]?.thumbnail?.url) as string,
                            title: title,
                            index: slideIndex,
                            id,
                        }),
                    2000
                );
            }
        },
    });
    const [animateCompleted, setAnimateCompleted] = useState(isEmpty(title) ?? false); // Initially set to true to start the animation

    const handleAnimationComplete = () => {
        setAnimateCompleted(true);
    };

    return (
        <Flex
            data-testid="Hero"
            w={"full"}
            mx="auto"
            height={height}
            position="relative"
            flexDirection={headingDirectionMap?.[headingPosition]}
            backgroundColor={backgroundColor}
            ref={ref}
            data-video-ready={isVideoPlaying}
        >
            {shouldRenderVideo && (
                <ReactPlayer
                    url={`https://ecco.wistia.com/medias/${hashedId}`}
                    height="100%"
                    width="100%"
                    style={{
                        top: 0,
                        left: 0,
                        overflow: "hidden",
                        zIndex: 1,
                    }}
                    id={"video-player-container"}
                    className={VideoStyles["react-player"]}
                    playing={true}
                    loop={true}
                    muted={true}
                    onPlay={() => {
                        setIsVideoPlaying(true);
                    }}
                    config={{
                        wistia: {
                            options: {
                                endVideoBehavior: "loop",
                                playButton: false,
                            },
                        },
                    }}
                />
            )}
            {shouldRenderImage && (
                <>
                    {!isEmpty(imageMobile) && (
                        <NextImage
                            loading={isFirstOnPage ? "eager" : "lazy"}
                            priority={isFirstOnPage}
                            alt={image?.fields?.title}
                            src={imageMobile?.fields?.file?.url}
                            sx={{
                                aspectRatio: ["2/3", "2/3"],
                            }}
                            display={{ base: "block", md: "none" }}
                            sizes={["200vw, 200vw, 100vw, 100vw"]}
                            position={isFullSizeImage ? ["relative"] : null}
                            fill={true}
                            objectFit={"cover"}
                            top={0}
                            left={0}
                            zIndex={1}
                        />
                    )}
                    <NextImage
                        loading={isFirstOnPage ? "eager" : "lazy"}
                        priority={isFirstOnPage}
                        alt={image?.fields?.title}
                        src={image?.fields?.file?.url}
                        sx={{
                            aspectRatio: ["2/3", "2/3", "16/9", "16/9"],
                        }}
                        display={{ base: !isEmpty(imageMobile) ? "none" : "block", md: "block" }}
                        sizes={["200vw, 200vw, 100vw, 100vw"]}
                        position={isFullSizeImage ? ["relative"] : null}
                        fill={true}
                        objectFit={"cover"}
                        top={0}
                        left={0}
                        zIndex={1}
                    />
                </>
            )}
            {shouldRenderHeading && (
                <Flex
                    style={{
                        position: "absolute",
                        height: "100%",
                        top: "0",
                        flexDirection: "column",
                        justifyContent: "end",
                        width: "100%",
                        backgroundImage:
                            "linear-gradient(180deg, rgba(0, 0, 0, 0.0) 0%, rgba(0, 0, 0, 0.02) 48.44%, rgba(0, 0, 0, 0.42) 100%)",
                    }}
                    zIndex={3}
                >
                    <Box
                        style={{
                            minHeight: "30%",
                            position: "sticky",
                            bottom: "0",
                        }}
                        zIndex={2}
                    >
                        <Box
                            p={[3, 6]}
                            mb={[1, 0, 0, 0]}
                            style={{
                                boxSizing: "border-box",
                                height: "100%",
                                display: "flex",
                                backgroundImage:
                                    "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.42) 48.44%, rgba(0, 0, 0, 0.42) 100%)",
                            }}
                        >
                            <Flex
                                flexDirection="column"
                                w={["100%", "100%", "70%"]}
                                justifyContent="flex-end"
                                sx={
                                    hasNavigation && {
                                        mb: {
                                            base: "14.4",
                                            sm: 0,
                                        },
                                    }
                                }
                            >
                                {title && (
                                    <MotionTitle
                                        sx={{ mb: space[2] }}
                                        handleAnimationComplete={handleAnimationComplete}
                                        title={title}
                                        isFirstOnPage={isFirstOnPage}
                                        data-contentful-entry-id={id}
                                        data-contentful-field-id={"title"}
                                    />
                                )}
                                <MotionDiv
                                    initial={{ opacity: 0 }}
                                    animate={animateCompleted ? { opacity: 1 } : { opacity: 0 }}
                                    transition={{ duration: 0.5 }}
                                >
                                    {description && (
                                        <Box
                                            sx={{
                                                ...styles.description,
                                                mb: !legalText ? ["6", "8"] : 2,
                                            }}
                                            color={"white"}
                                            data-contentful-entry-id={id}
                                            data-contentful-field-id={"description"}
                                        >
                                            {description}
                                        </Box>
                                    )}
                                    {legalText && (
                                        <Box
                                            sx={styles.legalText}
                                            color={"white"}
                                            data-contentful-entry-id={id}
                                            data-contentful-field-id={"legalText"}
                                        >
                                            {renderRichTextToComponent(legalText)}
                                        </Box>
                                    )}
                                    {callToActions && (
                                        <Flex gap={space[0.5]} wrap="wrap" maxW={[null, "80%"]}>
                                            {callToActions?.map((button, i) => {
                                                const fields = button?.fields;
                                                return (
                                                    <Button
                                                        sx={styles.button}
                                                        rightIcon={<ECCOIcon name="arrow-right" />}
                                                        as={Link}
                                                        key={`${button?.sys?.id} - ${i}`}
                                                        link={fields?.link}
                                                        variant={"standard"}
                                                        colorScheme={isBlack ? "black" : "white"}
                                                        data-contentful-entry-id={id}
                                                        data-contentful-field-id={"callToActions"}
                                                        onClick={() =>
                                                            AnalyticsClient.selectPromotion({
                                                                image: (image?.fields?.file?.url ??
                                                                    backgroundVideo?.items?.[0]
                                                                        ?.thumbnail?.url) as string,
                                                                title:
                                                                    title + (fields?.label ?? ""),
                                                                index: slideIndex,
                                                                id,
                                                            })
                                                        }
                                                    >
                                                        <span>{fields?.label}</span>
                                                    </Button>
                                                );
                                            })}
                                        </Flex>
                                    )}
                                </MotionDiv>
                            </Flex>
                        </Box>
                    </Box>
                </Flex>
            )}
        </Flex>
    );
};
