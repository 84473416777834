import type { FC, ReactNode } from "react";
import { useEffect } from "react";
import type { BoxProps } from "../../layout/box/Box";
import { Box } from "../../layout/box/Box";
import type { BackgroundProps } from "@chakra-ui/system";
import { useChangeBackgroundColor } from "../../data-display/scrollable-grid/hooks/useChangeBackgroundColor";
import { useContrastText } from "../../../hooks/useContrastText";
import { textContrast } from "./BackgroundColorWrapperStyles";

export interface BackgroundColorWrapperProps extends BoxProps {
    backgroundColor?: BackgroundProps["bgColor"];
    children: ReactNode | ReactNode[];
}

const DEFAULT_BACKGROUND_COLOR = "";

export const BackgroundColorWrapper: FC<BackgroundColorWrapperProps> = ({
    backgroundColor = DEFAULT_BACKGROUND_COLOR,
    children,
}) => {
    const { ref, currentBackgroundColor } = useChangeBackgroundColor(backgroundColor, {
        offsetTop: 240,
    });
    const { color: textColor } = useContrastText(currentBackgroundColor);

    useEffect(() => {
        if (textColor) {
            document.getElementById("main-content")?.setAttribute("style", `color: ${textColor}`);
        }
    }, [currentBackgroundColor, textColor]);

    return (
        <Box sx={textContrast(textColor)} ref={ref as unknown as React.LegacyRef<HTMLDivElement>}>
            {children}
        </Box>
    );
};
