import type { FC, ReactNode } from "react";
import { Children, useMemo } from "react";
import type { BoxProps } from "@chakra-ui/react";
import { Box, Grid } from "@chakra-ui/react";
import {
    calculateProductsSplit,
    getMediaModulesColumns,
    getProductColumn,
    getProductsRowsForMediaModulesMobile,
    getProductsColumnsForMediaModulesMobile,
    getProductsRowsForTopMediaModulesDesktop,
    getScrollableProductColumns,
    getScrollableProductIndex,
} from "./helpers";
import { useChangeBackgroundColor } from "./hooks/useChangeBackgroundColor";
import type { BackgroundProps } from "@chakra-ui/system";
import { useContrastText } from "../../../hooks/useContrastText";
import {
    bottomGridStyles,
    bottomMediaModulesStyles,
    bottomProductsStyles,
    bottomScrollableProductStyles,
    topGridStyles,
    topMediaModulesStyles,
    topProductsStyles,
    topScrollableProductStyles,
} from "./ScrollableGridStyles";

export interface ScrollableGridProps extends BoxProps {
    data: {
        products: ReactNode[];
        topMediaModules: ReactNode[];
        bottomMediaModules: ReactNode[];
        topMediaModulesPosition: string;
        bottomMediaModulesPosition: string;
    };
    gap?: string | number;
    productHeight?: string | number;
    backgroundColor?: BackgroundProps["bgColor"];
}

const DEFAULT_GAP = "5";
const DEFAULT_PRODUCT_HEIGHT = "23rem";
const DEFAULT_BACKGROUND_COLOR = "";
const productsColumns = 4;

export const ScrollableGrid: FC<ScrollableGridProps> = ({
    data,
    gap = DEFAULT_GAP,
    productHeight = DEFAULT_PRODUCT_HEIGHT,
    backgroundColor = DEFAULT_BACKGROUND_COLOR,
    ...rest
}) => {
    const {
        topMediaModules,
        bottomMediaModules,
        topMediaModulesPosition,
        bottomMediaModulesPosition,
        products,
    } = data;

    const topMediaModulesColumns = getMediaModulesColumns(topMediaModulesPosition);
    const bottomMediaModulesColumns = getMediaModulesColumns(bottomMediaModulesPosition);

    const { ref, currentBackgroundColor } = useChangeBackgroundColor(backgroundColor);
    const { color: textColor, isBlack } = useContrastText(currentBackgroundColor);

    const topMediaModulesArray = Children.toArray(topMediaModules);
    const bottomMediaModulesArray = Children.toArray(bottomMediaModules);
    const productsArray = Children.toArray(products);

    const topMediaModulesLength = topMediaModulesArray?.length || 0;
    const bottomMediaModulesLength = bottomMediaModulesArray?.length || 0;

    const { productsTop, productsBottom } = useMemo(
        () =>
            calculateProductsSplit(
                topMediaModulesArray,
                bottomMediaModulesArray,
                productsArray,
                productsColumns
            ),
        [topMediaModulesArray, bottomMediaModulesArray, productsArray]
    );

    const scrollableProductIndexTop = getScrollableProductIndex(
        productsTop?.length || 0,
        topMediaModulesPosition,
        productsColumns
    );

    const scrollableProductIndexBottom = getScrollableProductIndex(
        productsBottom?.length || 0,
        bottomMediaModulesPosition,
        productsColumns
    );

    const productsRowsTopDesktop = getProductsRowsForTopMediaModulesDesktop(
        productsTop,
        productsColumns,
        topMediaModulesLength
    );

    const productsRowsTopMobile = getProductsRowsForMediaModulesMobile(
        productsTop,
        topMediaModulesLength,
        scrollableProductIndexTop
    );

    const productsRowsBottomMobile = getProductsRowsForMediaModulesMobile(
        productsBottom,
        bottomMediaModulesLength,
        scrollableProductIndexBottom
    );

    const productsColumnsTopMobile = getProductsColumnsForMediaModulesMobile(
        productsTop,
        scrollableProductIndexTop
    );

    const productsColumnsBottomMobile = getProductsColumnsForMediaModulesMobile(
        productsBottom,
        scrollableProductIndexBottom
    );

    const scrollableProductColumnsTop = getScrollableProductColumns(topMediaModulesPosition);
    const scrollableProductColumnsBottom = getScrollableProductColumns(bottomMediaModulesPosition);

    const bottomGridMarginTop = productsTop.length > 3 ? gap : `-${productHeight}`;

    return (
        <Box
            ref={ref as unknown as React.LegacyRef<HTMLDivElement>}
            data-testid="product-grid-slider"
            {...rest}
        >
            <Grid sx={topGridStyles(gap)}>
                {Children.map(topMediaModules, (mediaModule, index) => (
                    <Box sx={topMediaModulesStyles(index, topMediaModulesColumns)}>
                        {mediaModule}
                    </Box>
                ))}

                {Children.map(productsTop, (product, index: number) => {
                    const isScrollableProduct = index === scrollableProductIndexTop;
                    const productColumn = getProductColumn(index, productsColumns);

                    return isScrollableProduct ? (
                        <Box
                            sx={topScrollableProductStyles(
                                productHeight,
                                scrollableProductColumnsTop,
                                topMediaModulesLength,
                                textColor,
                                isBlack
                            )}
                        >
                            {product}
                        </Box>
                    ) : (
                        <Box
                            sx={topProductsStyles(
                                index,
                                productHeight,
                                productsColumnsTopMobile,
                                productsRowsTopMobile,
                                productsRowsTopDesktop,
                                productColumn,
                                textColor,
                                isBlack
                            )}
                        >
                            {product}
                        </Box>
                    );
                })}
            </Grid>
            <Grid sx={bottomGridStyles(gap, bottomGridMarginTop)}>
                {Children.map(bottomMediaModules, (mediaModule, index) => (
                    <Box
                        sx={bottomMediaModulesStyles(
                            index,
                            bottomMediaModulesColumns,
                            topMediaModulesLength
                        )}
                    >
                        {mediaModule}
                    </Box>
                ))}

                {Children.map(productsBottom, (product, index: number) => {
                    const isScrollableProduct = index === scrollableProductIndexBottom;
                    const productColumn = getProductColumn(index, productsColumns);

                    return isScrollableProduct ? (
                        <Box
                            sx={bottomScrollableProductStyles(
                                productHeight,
                                scrollableProductColumnsBottom,
                                bottomMediaModulesLength,
                                textColor,
                                isBlack
                            )}
                        >
                            {product}
                        </Box>
                    ) : (
                        <Box
                            sx={bottomProductsStyles(
                                index,
                                productHeight,
                                productsColumnsBottomMobile,
                                productsRowsBottomMobile,
                                productColumn,
                                textColor,
                                isBlack
                            )}
                        >
                            {product}
                        </Box>
                    );
                })}
            </Grid>
        </Box>
    );
};
