import type { BoxProps, FlexProps, SystemStyleObject } from "@chakra-ui/react";
import { Button, Flex } from "@chakra-ui/react";
import type { CustomDomComponent } from "framer-motion";
import { motion } from "framer-motion";
import { debounce } from "lodash-es";
import type { FC } from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useIsomorphicLayoutEffect } from "../../../hooks/useIsomorphicLayoutEffect";
import { Box } from "../../layout/box/Box";
import { Text } from "../text/Text";

export interface ReadMoreProps extends FlexProps {
    ariaMore?: string;
    ariaLess?: string;
    showMoreLabel?: string;
    showLessLabel?: string;
    collapsedHeight: number | string;
    text?: string;
    textStyle?: object;
    noOfLines?: number;
    showButtonStyle?: SystemStyleObject;
    expandedClassname?: string;
}

const DEFAULT_SHOW_MORE_LABEL = "_Read more";
const DEFAULT_SHOW_LESS_LABEL = "_Read less";
const DEFAULT_COLLAPSED_HEIGHT = "2rem";
const DEFAULT_EXPANDED_CLASSNAME = "readmore-expanded";
const DEFAULT_NUMBER_OF_LINES = 2;

enum AnimationVariant {
    OPEN = "open",
    COLLAPSED = "collapsed",
    NO_CLAMP = "noClamp",
}

const MotionBox: CustomDomComponent<Omit<BoxProps, "transition">> = motion(Box);

const hasClamping = (el: HTMLDivElement) => {
    const { clientHeight, scrollHeight } = el;
    return clientHeight !== scrollHeight;
};

export const ReadMore: FC<ReadMoreProps> = ({
    children,
    showMoreLabel = DEFAULT_SHOW_MORE_LABEL,
    showLessLabel = DEFAULT_SHOW_LESS_LABEL,
    collapsedHeight = DEFAULT_COLLAPSED_HEIGHT,
    text,
    textStyle,
    noOfLines = DEFAULT_NUMBER_OF_LINES,
    showButtonStyle,
    expandedClassname,
    ariaLess = "",
    ariaMore = "",
    ...rest
}): JSX.Element => {
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const [showButton, setShowButton] = useState<boolean>(false);
    const [openHeight, setOpenHeight] = useState<number | "auto">("auto");

    const ref = useRef<HTMLDivElement>(null);

    const lastWindowSizeRef = useRef(0);

    const handleToggle = () => setIsExpanded((prevState) => !prevState);

    const animationVariants = {
        open: { height: openHeight },
        collapsed: { height: collapsedHeight, overflow: "hidden" },
        noClamp: { height: "auto" },
    };

    const [animationVariant, setAnimationVariant] = useState<AnimationVariant>(
        AnimationVariant.COLLAPSED
    );

    const checkButtonAvailability = useCallback(() => {
        if (window.innerWidth === lastWindowSizeRef.current) return;
        lastWindowSizeRef.current = window.innerWidth;

        if (ref.current) {
            const hasClampingValue = hasClamping(ref.current);
            setOpenHeight(ref.current.scrollHeight);

            if (!showButton && !hasClampingValue && !isExpanded) {
                setAnimationVariant(AnimationVariant.NO_CLAMP);
            }

            if (hasClampingValue !== showButton) {
                setShowButton(hasClampingValue);
            }

            if (isExpanded && !hasClampingValue) {
                setIsExpanded(false);
            }
        }
    }, [isExpanded, showButton]);

    useIsomorphicLayoutEffect(() => {
        const debouncedCheck = debounce(checkButtonAvailability, 300);

        window.addEventListener("resize", debouncedCheck);

        return () => {
            window.removeEventListener("resize", debouncedCheck);
        };
    }, [checkButtonAvailability]);

    useEffect(() => {
        setTimeout(() => checkButtonAvailability(), 300);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [children]);

    return (
        <Flex flexDirection="column" alignItems="flex-start" {...rest}>
            <MotionBox
                key="content"
                initial={AnimationVariant.COLLAPSED}
                animate={isExpanded ? AnimationVariant.OPEN : animationVariant}
                variants={animationVariants}
                transition={{ duration: 0.3, ease: [0.33, 1, 0.68, 1] }}
                ref={ref}
            >
                <Text
                    className={isExpanded ? expandedClassname ?? DEFAULT_EXPANDED_CLASSNAME : ""}
                    sx={textStyle}
                    noOfLines={showButton && !isExpanded ? noOfLines : undefined}
                >
                    {text}
                </Text>
            </MotionBox>
            {showButton && (
                <Button
                    aria-label={isExpanded ? ariaLess || showLessLabel : ariaMore || showMoreLabel}
                    p={0}
                    background="none"
                    _hover={{ background: "none" }}
                    display="flex"
                    onClick={handleToggle}
                    h="100%"
                    mt={2}
                    variant="link"
                    sx={showButtonStyle}
                >
                    <Text color="black">{isExpanded ? showLessLabel : showMoreLabel}</Text>
                </Button>
            )}
        </Flex>
    );
};
