import type { FC } from "react";
import { createElement } from "react";
import { ModuleNotFound } from "@lib/contentful/modules-renderer/ModuleNotFound";
import type { Module } from "@lib/contentful/modules-renderer/ModuleType";
import type { Document } from "@contentful/rich-text-types";

interface IModuleCreatorProps {
    module: Module;
    index: number;
    type: string;
    component: FC<{
        module: Module;
        index?: number;
    }>;
}

export interface IModuleRenderedProps<T> {
    module: T;
    index?: number; // Position of the module on the page (is defined when the module is rendered by Contentful)
    isLeftColumn?: boolean;
    isRightColumn?: boolean;
    authorizationInvitation?: Document;
}

export const ModuleCreator: FC<IModuleCreatorProps> = ({
    module,
    index,
    type,
    component,
    ...rest
}) => {
    if (typeof component === "undefined") {
        return <ModuleNotFound type={type} />;
    }

    return createElement(component, { module, index, ...rest });
};
