import { useEffect, useState } from "react";
import fontColorContrast from "font-color-contrast";

export const useContrastText = (bgColor: string) => {
    const whiteColor = "#ffffff";
    const blackColor = "#000000";

    // If the background color is empty, we'll use white as the default color
    const backgroundColor = bgColor || whiteColor;

    const [color, setColor] = useState<string>("");

    useEffect(() => {
        setColor(fontColorContrast(backgroundColor));
    }, [backgroundColor, blackColor, whiteColor]);

    const isWhite = color === whiteColor;
    const isBlack = color === blackColor;

    return { color, isWhite, isBlack };
};
