import type { FC } from "react";
import { useRef, useState } from "react";
import type { BoxProps } from "@ui/components/layout/box/Box";
import { Box } from "@ui/components/layout/box/Box";
import { Text } from "@ui/components/content/text/Text";
import type { IModuleVideo } from "@contentful-api/types/contentful";
import { H } from "@ui/components/content/heading/A11yHeading";
import { Flex } from "@ui/components/layout/flex/Flex";
import type ReactPlayerProps from "react-player/lazy";
import { IconButton } from "@ui/components/forms/icon-button/IconButton";
import { ECCOIcon } from "@ui/components/media-and-icons/ecco-icon/ECCOIcon";
import dynamic from "next/dynamic";
import VideoStyles from "@components/cms/video/video.module.css";
import type { IModuleRenderedProps } from "@lib/contentful/modules-renderer/ModuleCreator";

type Width = IModuleVideo["fields"]["videoWidth"] | "100%" | "full";

const ReactPlayer = dynamic(() => import("react-player/lazy"), { ssr: false });

const widthMap: Record<Width, BoxProps["width"]> = {
    medium: ["full", "full", "full", "full", "70%"],
    large: ["full", "full", "full", "full", "80%"],
    "full width": "full",
    "100%": "full",
    full: "full",
};

interface IVideoProps extends IModuleRenderedProps<IModuleVideo> {
    width?: Width;
    height?: string;
}

export const Video: FC<IVideoProps> = ({ module, width, height }) => {
    const title = module?.fields?.title;
    const description = module?.fields?.description;
    const videoReference = module?.fields?.videoReference;
    const hashedId: string = videoReference?.items[0]?.hashed_id;
    const videoWidth: Width = width || module?.fields?.videoWidth || "full width";
    const autoplay = module?.fields?.autoplay || false;
    const muted = module?.fields?.muted || false;
    const loop = module?.fields?.loop || false;
    const showVideoControls = module?.fields?.showVideoControls || false;

    const playerRef = useRef<ReactPlayerProps>(null);
    const [overlayVisible, setOverlayVisible] = useState(!autoplay);

    return (
        <>
            <Flex
                gap={2}
                mb={4}
                width={widthMap?.[videoWidth]}
                mx="auto"
                flexDirection="column"
                pl={[
                    "layoutMargin.mobile",
                    "layoutMargin.tab",
                    "layoutMargin.desktop",
                    "layoutMargin.desktop",
                ]}
                data-contentful-entry-id={module?.sys?.id}
                data-contentful-field-id={"title"}
            >
                {title && (
                    <H size="h1" aria-label={title}>
                        {title}
                    </H>
                )}
                {description && (
                    <Text fontWeight="bold" color="black">
                        {description}
                    </Text>
                )}
            </Flex>

            <Box
                width={widthMap?.[videoWidth]}
                position="relative"
                borderRadius="5px"
                mx="auto"
                h={height || ["30.5rem", "30.5rem", "37.5rem", "37.5rem", "47rem"]}
                style={{ aspectRatio: "16/9" }}
            >
                <ReactPlayer
                    fallback={<Box />}
                    className={VideoStyles["react-player"]}
                    url={`https://ecco.wistia.com/medias/${hashedId}`}
                    height="100%"
                    width="100%"
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        overflow: "hidden",
                    }}
                    controls={showVideoControls}
                    playing={autoplay}
                    muted={muted}
                    volume={!muted ? 0.5 : 0}
                    loop={loop}
                    ref={playerRef}
                    config={{
                        wistia: {
                            // Wistia options: https://wistia.com/support/developers/embed-options#endvideobehavior
                            options: {
                                volumeControl: true,
                                endVideoBehavior: loop ? "loop" : "reset",
                                playButton: false,
                            },
                        },
                    }}
                />
                {overlayVisible && (
                    <Flex
                        position="absolute"
                        w="full"
                        h="full"
                        zIndex={2}
                        alignItems="center"
                        justifyContent="center"
                        _hover={{
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            playerRef?.current?.getInternalPlayer?.()?.play();
                            setOverlayVisible(false);
                        }}
                    >
                        <IconButton
                            variant="solid"
                            colorScheme="white"
                            aria-label="Play"
                            h="12"
                            w="12"
                            data-testid="playvideo"
                            onClick={() => {
                                playerRef?.current?.getInternalPlayer?.()?.play();
                                setOverlayVisible(false);
                            }}
                            icon={<ECCOIcon name="play" />}
                        />
                    </Flex>
                )}
            </Box>
        </>
    );
};
