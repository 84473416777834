import { defaultUnderlineStyle } from "@ui/css/common";
export const styles = {
    description: {
        fontSize: ["mobileBodyTextLarge", "tableHeading3", "desktopBodyTextLarge"],
        fontWeight: "bold",
    },
    heading: {},
    legalText: {
        mb: ["6", "8"],
        fontSize: { base: "mobileBodyB4", lg: "desktopBodyTextNormal" },
        "& a, & button[data-type='modal-link']": {
            color: "primary",
            ...defaultUnderlineStyle,
            fontWeight: "normal",
            "&:hover": {
                color: "white !important",
            },
        },
    },
    button: {
        display: "flex",
        height: ["9.6", "10.4"],
        py: ["3", "3"],
        px: ["3", "4"],
        justifyContent: "center",
        alignItems: "center",
        gap: "3",
        fontSize: ["mobileBodyTextNormal", "desktopBodyTextNormal"],
        fontWeight: "bold",
        width: ["unset", "unset", "unset"],
        textDecoration: "none !important",
        _hover: {
            textDecoration: "none !important",
        },
    },
};
