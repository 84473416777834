import type { FC } from "react";
import { Hero } from "@components/cms/hero/Hero";
import { Text } from "@components/cms/text/Text";
import { Image } from "@components/cms/image/Image";
import { Video } from "@components/cms/video/Video";
import { Spacer } from "@components/cms/spacer/Spacer";
import { Hotspot } from "@components/cms/hotspot/Hotspot";
import { Headline } from "@components/cms/headline/Headline";
import { Accordion } from "@components/cms/accordion/Accordion";
import { HeroSlider } from "@components/cms/hero-slider/HeroSlider";
import type { Module } from "@lib/contentful/modules-renderer/ModuleType";
import { CardGeneric } from "@components/cms/card-generic/CardGeneric";
import { MultipleMedia } from "@components/cms/multiple-media/MultipleMedia";
import { StoryTileGrid } from "@components/cms/story-tile-grid/StoryTileGrid";
import { ProductGridSlider } from "@components/cms/product-grid-slider/ProductGridSlider";
import { CardLinkCollection } from "@components/cms/card-link-collection/CardLinkCollection";
import { BackgroundColorWrapperModule } from "@components/cms/background-color-wrapper/BackgroundColorWrapper";
import { CustomProductCarousel } from "@components/cms/custom-product-carousel/CustomProductCarousel";

export type ModulesMap = {
    [key in Module["sys"]["contentType"]["sys"]["id"]]?: FC;
};

export const modulesMap: ModulesMap = {
    moduleHero: Hero,
    moduleText: Text,
    moduleImage: Image,
    moduleVideo: Video,
    moduleSpacer: Spacer,
    moduleHotspot: Hotspot,
    moduleHeadline: Headline,
    moduleAccordion: Accordion,
    moduleHeroSlider: HeroSlider,
    moduleCardGeneric: CardGeneric,
    moduleMultipleMedia: MultipleMedia,
    moduleStoryTileGrid: StoryTileGrid,
    moduleProductGridSlider: ProductGridSlider,
    moduleCardLinkCollection: CardLinkCollection,
    moduleCustomProductCarousel: CustomProductCarousel,
    moduleBackgroundColorWrapper: BackgroundColorWrapperModule,
};
