import { useEffect, useMemo, useRef, useState } from "react";
import type { BackgroundProps } from "@chakra-ui/system";
import { useToken } from "@chakra-ui/system";
import { useMergeRefs } from "@chakra-ui/react";
import { useInView } from "react-intersection-observer";

type Options = {
    offsetTop: number;
};

const colorToHex = (color: number) => {
    const hexadecimal = color.toString(16);
    return hexadecimal.length == 1 ? "0" + hexadecimal : hexadecimal;
};

const RGBtoHex = (red: number, green: number, blue: number) => {
    return "#" + colorToHex(red) + colorToHex(green) + colorToHex(blue);
};

const DEFAULT_BACKGROUND_COLOR = "";
const DEFAULT_OPTIONS = {
    offsetTop: 180,
};

export const useChangeBackgroundColor = (
    backgroundColor: BackgroundProps["bgColor"] = DEFAULT_BACKGROUND_COLOR,
    options?: Options
) => {
    // Use the Chakra UI theme to get the color or fallback to the passed value that can be a color string
    const color: string =
        useToken("colors", backgroundColor as string) || (backgroundColor as string);

    const previousColor = useMemo(() => {
        if ("undefined" !== typeof window) {
            return document?.body?.style?.backgroundColor;
        }

        return "";
    }, []);

    const [currentBackgroundColor, setCurrentBackgroundColor] = useState(previousColor);

    // The offsetTop is the distance from the top of the viewport where we want to change the background color1
    const { offsetTop } = options || DEFAULT_OPTIONS;

    const elementRef = useRef<HTMLDivElement>(null);
    const { ref: inViewRef, inView } = useInView();

    const ref = useMergeRefs(elementRef, inViewRef);

    const updateBackgroundColor = (color: string) => {
        setCurrentBackgroundColor((prevCurrentColor) =>
            prevCurrentColor !== color ? color : prevCurrentColor
        );
    };

    useEffect(() => {
        if (color && "undefined" !== typeof window) {
            const evaluateBackgroundColorChange = () => {
                if (!elementRef?.current) return;

                if (!inView) {
                    return;
                }

                const { top, height } = elementRef.current.getBoundingClientRect();

                // When getting the background color of the body, the returned value is a string representing the RGB values
                // We need to convert it to a hex value to be able to compare it with the color we want to set.
                const bodyBackgroundColor = document.body.style.backgroundColor
                    .match(/\d+/g)
                    ?.map((el) => parseInt(el));

                const bodyBackgroundColorHex = RGBtoHex(
                    bodyBackgroundColor?.[0] || 0,
                    bodyBackgroundColor?.[1] || 0,
                    bodyBackgroundColor?.[2] || 0
                );

                // If the top of the element is below the top of the viewport + top offset
                // we want to reset the background color of the body to the previous one.
                if (top > offsetTop && bodyBackgroundColorHex === color) {
                    document.body.style.backgroundColor = previousColor;
                    updateBackgroundColor(previousColor);
                    return;
                }

                // If we already passed the bottom of the element, we want to reset the background color of the body
                if (height + top <= offsetTop) {
                    document.body.style.backgroundColor = previousColor;
                    updateBackgroundColor(previousColor);
                    return;
                }

                // As soon as the top of the viewport + top offset is reached by
                // the top of the element, change the background color of the body.
                if (top <= offsetTop) {
                    document.body.style.transition = "background-color 0.2s ease-in";
                    document.body.style.backgroundColor = color;
                    updateBackgroundColor(color);
                }
            };

            window.addEventListener("scroll", evaluateBackgroundColorChange);

            return () => {
                window.removeEventListener("scroll", evaluateBackgroundColorChange);

                // Reset the background color to the previous one when the component is unmounted
                document.body.style.backgroundColor = previousColor;
            };
        }
    }, [color, inView, offsetTop, previousColor]);

    return { ref, currentBackgroundColor };
};
