import type { FlexProps } from "../flex/Flex";
import { Flex } from "../flex/Flex";
import type { FC } from "react";
import type { Document } from "@contentful/rich-text-types";
import { isValidElement } from "react";

const sizes = {
    sm: [3, 3, 5],
    md: [4, 4, 12],
};

export interface ICardProps extends FlexProps {
    size?: "sm" | "md";
    isLeftColumn?: boolean;
    isRightColumn?: boolean;
    mergeWithPreviousCard?: boolean;
    authorizationInvitation?: Document;
}

export const Card: FC<ICardProps> = ({
    authorizationInvitation,
    mergeWithPreviousCard,
    isLeftColumn,
    isRightColumn,
    children,
    size = "sm",
    ...rest
}) => {
    // As per design they need to show privacy policy links in a different way between mobile and desktop.
    //We should ensure the key is always fixexd on the CMS level and hide it from the Right Column because will be on the bottom
    const isPrivacyPolicyLinks =
        isValidElement(children) &&
        children.props &&
        children.props.module.fields.key === "privacyPolicyLinks";
    // We wamt the deepLinking navigation card hidden on mobile, so we check for that
    // If LeftColumn is true, we will show it, we are handling that case on TwoColumnsPage.tsx
    const isPrivacyPolicyLinksCard = !isLeftColumn && isPrivacyPolicyLinks;

    return (
        <Flex
            flexDirection="column"
            backgroundColor="white"
            py={sizes[size]}
            px={sizes[size]}
            gap={4}
            display={{ base: isPrivacyPolicyLinksCard ? "none" : "flex", lg: "flex" }}
            mt={mergeWithPreviousCard ? -9 : "unset"}
            pt={mergeWithPreviousCard ? 9 : sizes[size]}
            {...rest}
        >
            {children}
        </Flex>
    );
};
