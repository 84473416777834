import type { FC, ReactNode } from "react";
import { H } from "../../content/heading/A11yHeading";
import { Text } from "../../content/text/Text";
import { Box } from "../../layout/box/Box";
import { Flex } from "../../layout/flex/Flex";

const widthMap = {
    medium: ["full", "full", "full", "full"],
    large: ["full", "full", "full", "full"],
    "full width": "full",
    "100%": "full",
    full: "full",
};

const positionMap = {
    left: "flex-start",
    right: "flex-end",
    center: "center",
};

export interface IStoryGridProps {
    title: string;
    description: string;
    textAlign: "left" | "center" | "right";
    columns: number;
    width: "medium" | "large" | "full width" | "100%" | "full";
    children: ReactNode;
}

export const StoryGrid: FC<IStoryGridProps> = ({
    title,
    description,
    textAlign,
    columns,
    children,
    width,
}) => {
    return (
        <Box w={widthMap[width]} mx="auto">
            {(title || description) && (
                <Flex
                    px={[3, 4, 6]}
                    paddingTop={[12, 24]}
                    gap={2}
                    mb={4}
                    textAlign={textAlign}
                    flexDirection="column"
                    alignItems={positionMap[textAlign]}
                >
                    {title && (
                        <H
                            w={{
                                md: "60%",
                            }}
                            as="h5"
                            aria-label={title}
                            fontSize={["mobileH1", "desktopH1"]}
                        >
                            {title}
                        </H>
                    )}
                    {description && (
                        <Text
                            w={{
                                md: "60%",
                            }}
                            fontSize={["mobileBodyB1", "desktopH3"]}
                            fontWeight="bold"
                        >
                            {description}
                        </Text>
                    )}
                </Flex>
            )}
            <Box
                display={["flex", "flex", "grid"]}
                flexDirection="column"
                w="full"
                gridTemplateColumns={`repeat(${columns}, 1fr)`}
            >
                {children}
            </Box>
        </Box>
    );
};
