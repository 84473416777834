import { Box } from "@ui/components/layout/box/Box";

export const ModuleError = (props) => (
    <Box
        sx={{
            width: "100%",
            p: 4,
            textAlign: "center",
            bg: "red.50",
            color: "red.600",
            border: "1",
            borderColor: "red.600",
            fontSize: ["desktopBodyTextTiny", "desktopBodyTextSmall"],
        }}
        {...props}
    />
);
