import Script from "next/script";
import { Box } from "@ui/components/layout/box/Box";
import type { IModuleVideo } from "@contentful-api/types/contentful";
import type { FC } from "react";
import { useState } from "react";

declare const window: Window &
    typeof globalThis & {
        _wq: any;
    };

interface IVideoProps {
    module: IModuleVideo;
}

export const VideoPlayer: FC<IVideoProps> = ({ module }) => {
    const hashedId = module?.fields?.videoReference?.items[0]?.hashed_id;
    const [videoReady, setVideoReady] = useState<boolean>(false);
    if (typeof window !== "undefined" && hashedId) {
        window._wq = window._wq || [];
        window._wq.push({
            id: hashedId,
            options: {
                autoPlay: true,
                muted: true,
                endVideoBehavior: "loop",
                volume: 0,
                volumeControl: false,
                controlsVisibleOnLoad: false,
                settingsControl: false,
                fullscreenButton: false,
                smallPlayButton: false,
                playbackRateControl: false,
                fitStrategy: "cover",
                playbar: false,
                preload: true,
            },
            onReady: (video: any) => {
                video.bind("play", () => {
                    video.hasData() && setVideoReady(true);
                    video.play();
                });
            },
        });
    }

    return (
        <>
            <Script src="https://fast.wistia.com/assets/external/E-v1.js" async></Script>
            <Box
                id={"video-wistia-container"}
                data-video-ready={videoReady}
                sx={{
                    pointerEvents: "none",
                    width: "100%",
                    height: "100%",
                    position: "relative",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                }}
            >
                <div
                    className={`wistia_embed wistia_async_${hashedId} `}
                    style={{
                        height: "100%",
                        position: "relative",
                        width: "100%",
                        borderRadius: "16px",
                        overflow: "hidden",
                        isolation: "isolate",
                    }}
                ></div>
            </Box>
        </>
    );
};
