import type { BoxProps, GridProps } from "@chakra-ui/react";
import type { ScrollableGridProps } from "./ScrollableGrid";
import type {
    getMediaModulesColumns,
    getProductColumn,
    getProductsRowsForMediaModulesMobile,
    getProductsColumnsForMediaModulesMobile,
    getProductsRowsForTopMediaModulesDesktop,
    getScrollableProductColumns,
} from "./helpers";

const textContrast = (textColor: string, isBlack: boolean): BoxProps["sx"] => ({
    "& .product-name": {
        color: textColor,
    },
    "& .product-colors": {
        color: isBlack ? "gray.200" : "gray.150",
    },
    "& .product-price": {
        color: textColor,
    },
    "& .product-discounted-price": {
        color: isBlack ? "gray.200" : "gray.150",
    },
});

export const topGridStyles = (gap: ScrollableGridProps["gap"]): GridProps["sx"] => ({
    gridTemplateColumns: ["1fr 1fr", "1fr 1fr", "1fr 1fr", "1fr 1fr 1fr 1fr"],
    w: "full",
    gap,
});

export const topMediaModulesStyles = (
    index: number,
    topMediaModulesColumns: ReturnType<typeof getMediaModulesColumns>
): BoxProps["sx"] => ({
    h: ["28rem", "28rem", "28rem", "81rem"],
    gridColumn: ["1/-1", "1/-1", "1/-1", topMediaModulesColumns],
    gridRow: index + 1,
});

export const topScrollableProductStyles = (
    productHeight: ScrollableGridProps["productHeight"],
    scrollableProductColumnsTop: ReturnType<typeof getScrollableProductColumns>,
    topMediaModulesLength: number,
    textColor: string,
    isBlack: boolean
): BoxProps["sx"] => ({
    h: productHeight,
    gridColumn: ["1/-1", "1/-1", "1/-1", scrollableProductColumnsTop],
    gridRow: [
        `${topMediaModulesLength + 1}`,
        `${topMediaModulesLength + 1}`,
        `${topMediaModulesLength + 1}`,
        `1/${topMediaModulesLength + 2}`,
    ],
    position: ["unset", "unset", "unset", "sticky"],
    top: ["unset", "unset", "unset", 40],
    ...textContrast(textColor, isBlack),
});

export const topProductsStyles = (
    index: number,
    productHeight: ScrollableGridProps["productHeight"],
    productsColumnsTopMobile: ReturnType<typeof getProductsColumnsForMediaModulesMobile>,
    productsRowsTopMobile: ReturnType<typeof getProductsRowsForMediaModulesMobile>,
    productsRowsTopDesktop: ReturnType<typeof getProductsRowsForTopMediaModulesDesktop>,
    productColumn: ReturnType<typeof getProductColumn>,
    textColor: string,
    isBlack: boolean
): BoxProps["sx"] => ({
    h: productHeight,
    gridColumn: [
        productsColumnsTopMobile[index],
        productsColumnsTopMobile[index],
        productsColumnsTopMobile[index],
        productColumn,
    ],
    gridRow: [
        productsRowsTopMobile[index],
        productsRowsTopMobile[index],
        productsRowsTopMobile[index],
        productsRowsTopDesktop[index],
    ],
    ...textContrast(textColor, isBlack),
});

export const bottomGridStyles = (
    gap: string | number,
    bottomGridMarginTop: string | number
): GridProps["sx"] => ({
    gridTemplateColumns: ["1fr 1fr", "1fr 1fr", "1fr 1fr", "1fr 1fr 1fr 1fr"],
    w: "full",
    // We need a bit of a bigger row gap on small screens to make sure that products with discount tags don't overlap
    rowGap: [8, gap, gap, gap],
    columnGap: gap,
    mt: [gap, gap, gap, bottomGridMarginTop],
});

export const bottomMediaModulesStyles = (
    index: number,
    bottomMediaModulesColumns: ReturnType<typeof getMediaModulesColumns>,
    topMediaModulesLength: number
): BoxProps["sx"] => ({
    h: ["28rem", "28rem", "28rem", "81rem"],
    gridColumn: ["1/-1", "1/-1", "1/-1", bottomMediaModulesColumns],
    gridRow: [index + 1, index + 1, index + 1, index + 2],
    mt: index === 0 && topMediaModulesLength > 0 ? [8, 8, 8, 40] : "unset",
});

export const bottomScrollableProductStyles = (
    productHeight: ScrollableGridProps["productHeight"],
    scrollableProductColumnsBottom: ReturnType<typeof getScrollableProductColumns>,
    bottomMediaModulesLength: number,
    textColor: string,
    isBlack: boolean
): BoxProps["sx"] => ({
    h: productHeight,
    gridColumn: ["1/-1", "1/-1", "1/-1", scrollableProductColumnsBottom],
    gridRow: [
        bottomMediaModulesLength + 1,
        bottomMediaModulesLength + 1,
        bottomMediaModulesLength + 1,
        "1/-1",
    ],
    position: ["unset", "unset", "unset", "sticky"],
    top: ["unset", "unset", "unset", 32],
    ...textContrast(textColor, isBlack),
});

export const bottomProductsStyles = (
    index: number,
    productHeight: ScrollableGridProps["productHeight"],
    productsColumnsBottomMobile: ReturnType<typeof getProductsColumnsForMediaModulesMobile>,
    productsRowsBottomMobile: ReturnType<typeof getProductsRowsForMediaModulesMobile>,
    productColumn: ReturnType<typeof getProductColumn>,
    textColor: string,
    isBlack: boolean
): BoxProps["sx"] => ({
    h: productHeight,
    gridColumn: [
        productsColumnsBottomMobile[index],
        productsColumnsBottomMobile[index],
        productsColumnsBottomMobile[index],
        productColumn,
    ],
    gridRow: [
        productsRowsBottomMobile[index],
        productsRowsBottomMobile[index],
        productsRowsBottomMobile[index],
        "1",
    ],
    ...textContrast(textColor, isBlack),
});
