import type { ScrollableGridProps } from "./ScrollableGrid";
import { isEmpty } from "lodash-es";

export const getMediaModulesColumns = (position: string) => (position === "left" ? "1/4" : "2/-1");

export const getScrollableProductColumns = (position: string) =>
    position === "left" ? "4/-1" : "1/2";

export const getProductColumn = (productIndex: number, productsColumns: number) => {
    const column = productIndex % productsColumns;
    const columnStart = column === 0 ? 1 : column + 1;
    const columnEnd = columnStart + 1;

    return `${columnStart}/${columnEnd}`;
};

export const getProductsRowsForTopMediaModulesDesktop = (
    products: ScrollableGridProps["data"]["products"],
    productsColumns: number,
    topMediaModulesLength: number
) => {
    let currentRow = topMediaModulesLength + 1;

    return products.map((_, index) => {
        const isNewRow = index % productsColumns === 0;

        // Only increment row if there are more that 4 products
        if (index > 3 && isNewRow) {
            ++currentRow;
        }

        return currentRow;
    });
};

export const getProductsRowsForMediaModulesMobile = (
    products: ScrollableGridProps["data"]["products"],
    mediaModulesLength: number,
    scrollableProductIndex: number
) => {
    // We want to start after the length of the media modules + 1 of the scrollable product
    let currentRow = mediaModulesLength + 1;

    // Filter out the scrollable product from the products array so it doesn't get a row
    const productsRows = products
        .filter((_, index) => index !== scrollableProductIndex)
        .map((_, index) => {
            // Increment row after every 2 products
            if (index % 2 === 0) {
                ++currentRow;
            }

            return currentRow;
        });

    // Insert the scrollable product row at the correct index with a value of 0
    // 0 will be used to calculate the column span for the scrollable product
    return [
        ...productsRows.slice(0, scrollableProductIndex),
        0,
        ...productsRows.slice(scrollableProductIndex),
    ];
};

export const getProductsColumnsForMediaModulesMobile = (
    products: ScrollableGridProps["data"]["products"],
    scrollableProductIndex: number
) => {
    const productsColumnsMobile = products
        .filter((_, index) => index !== scrollableProductIndex)
        .map((_, index, arr) => {
            const isLastRow = index === arr.length - 1;
            const isArrOdd = arr.length % 2 !== 0;

            // If we are on th
            if (isArrOdd && isLastRow) {
                return "1/-1";
            }

            return index % 2 === 0 ? "1/2" : "2/-1";
        });

    return [
        ...productsColumnsMobile.slice(0, scrollableProductIndex),
        "1/-1",
        ...productsColumnsMobile.slice(scrollableProductIndex),
    ];
};

export const calculateProductsSplit = (
    topMediaModules: ScrollableGridProps["data"]["topMediaModules"],
    bottomMediaModules: ScrollableGridProps["data"]["bottomMediaModules"],
    products: ScrollableGridProps["data"]["products"],
    productsColumns: number
) => {
    if (isEmpty(products)) {
        return {
            productsTop: [],
            productsBottom: [],
        };
    }

    if (isEmpty(bottomMediaModules)) {
        return {
            productsTop: products,
            productsBottom: [],
        };
    }

    if (isEmpty(topMediaModules)) {
        return {
            productsTop: [],
            productsBottom: products,
        };
    }

    // This will always balance the majority of the products to the first row
    // and try to leave the last row with only 4 products.
    // E.g. 12 products will be split into 8 and 4
    // E.g. 4 products will be split into 3 and 1 (This is to have the possibility to scroll the product in the last row along the bottom media modules)
    const lastRow = Math.max(
        products.length - productsColumns,
        products.length === productsColumns ? productsColumns - 1 : productsColumns
    );

    return {
        productsTop: products.slice(0, lastRow),
        productsBottom: products.slice(lastRow),
    };
};

export const getScrollableProductIndex = (
    productsLength: number,
    position: string,
    productsColumns: number
) => {
    if (productsLength < productsColumns) return 0;

    return position === "left" ? productsColumns - 1 : 0;
};
