import type { FC } from "react";
import { Image } from "@components/cms/image/Image";

import type { GridProps } from "@ui/components/layout/grid/Grid";
import { Grid, GridItem } from "@ui/components/layout/grid/Grid";
import type {
    IModuleMultipleMedia,
    IModuleImage,
    IModuleVideo,
} from "@contentful-api/types/contentful";
import { Video } from "@components/cms/video/Video";
import type { IModuleRenderedProps } from "@lib/contentful/modules-renderer/ModuleCreator";

type MediaModuleMap = {
    [key in
        | IModuleImage["sys"]["contentType"]["sys"]["id"]
        | IModuleVideo["sys"]["contentType"]["sys"]["id"]]?: FC<any>;
};

const widthMap: Record<IModuleMultipleMedia["fields"]["mediaWidth"], GridProps["width"]> = {
    medium: ["full", "full", "full", "full", "70%"],
    large: ["full", "full", "full", "full", "80%"],
    "full width": "full",
};

const mediaModuleMap: MediaModuleMap = {
    moduleImage: Image,
    moduleVideo: Video,
};

interface IMultipleMediaProps extends IModuleRenderedProps<IModuleMultipleMedia> {}

export const MultipleMedia: FC<IMultipleMediaProps> = ({ module }) => {
    const mediaModules = module?.fields?.mediaModules;
    const mediaWidth = module?.fields?.mediaWidth || "full width";

    return (
        <Grid
            h="45rem"
            gridTemplateColumns={[null, null, null, "1fr 1fr"]}
            gridAutoRows="1fr 1fr"
            gap={[3, 4, 5]}
            w={widthMap[mediaWidth]}
            mx="auto"
        >
            {mediaModules?.map((module, i) => {
                const moduleType = module.sys.contentType.sys.id;

                const Component = mediaModuleMap[moduleType];

                return (
                    <GridItem key={`${module?.sys?.id} - ${i}`} h="full">
                        <Component module={module} width="full" height="full" />
                    </GridItem>
                );
            })}
        </Grid>
    );
};
