import { Hero } from "@components/cms/hero/Hero";
import type { IModuleHeroSlider } from "@contentful-api/types/contentful";
import { CommonCMS } from "@lib/constants/contentful";
import { useMicrocopy } from "@lib/contentful/microcopy/MicrocopyContext";
import type { IModuleRenderedProps } from "@lib/contentful/modules-renderer/ModuleCreator";
import type { BoxProps } from "@ui/components/layout/box/Box";
import { Box } from "@ui/components/layout/box/Box";
import { CarouselProductImage } from "@ui/components/navigation/carousel/Carousel";
import type { FC } from "react";

const widthMap: Record<IModuleHeroSlider["fields"]["heroSliderWidth"], BoxProps["width"]> = {
    medium: ["full", "full", "full", "full", "70%"],
    large: ["full", "full", "full", "full", "80%"],
    "full width": "full",
};

interface IHeroSliderProps extends IModuleRenderedProps<IModuleHeroSlider> {}

export const HeroSlider: FC<IHeroSliderProps> = ({ module, index }) => {
    const heroSliderWidth = module?.fields?.heroSliderWidth || "full width";
    const { getMultiple } = useMicrocopy();

    const microcopies = (() => {
        return getMultiple(CommonCMS.global, [
            CommonCMS.slide.nextSlide,
            CommonCMS.slide.previousSlide,
        ]);
    })();
    return (
        <Box w={widthMap[heroSliderWidth]} data-testid="HeroSlider">
            <CarouselProductImage
                nextSlideTitle={microcopies[CommonCMS.slide.nextSlide]}
                previousSlideTitle={microcopies[CommonCMS.slide.previousSlide]}
                timer={module.fields?.timer}
            >
                {module?.fields?.moduleHero?.map((hero, heroIndex) => (
                    <Hero
                        hasNavigation
                        key={hero.sys?.id}
                        module={hero}
                        index={index}
                        slideIndex={heroIndex}
                    />
                ))}
            </CarouselProductImage>
        </Box>
    );
};
