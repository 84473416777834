import type { IModuleBackgroundColorWrapper } from "@contentful-api/types/contentful";
import { BackgroundColorWrapper } from "@ui/components/editorial/background-color-wrapper/BackgroundColorWrapper";
import type { FC } from "react";
import { ModulesRenderer } from "@lib/contentful/modules-renderer/ModulesRenderer";
import type { Module } from "@lib/contentful/modules-renderer/ModuleType";
import type { IModuleRenderedProps } from "@lib/contentful/modules-renderer/ModuleCreator";

interface IBackgroundColorWrapperProps
    extends IModuleRenderedProps<IModuleBackgroundColorWrapper> {}

export const BackgroundColorWrapperModule: FC<IBackgroundColorWrapperProps> = ({ module }) => {
    return (
        <BackgroundColorWrapper backgroundColor={module?.fields?.backgroundColor?.value}>
            <ModulesRenderer modules={module?.fields?.modules as Module[]} />
        </BackgroundColorWrapper>
    );
};
