import { CommonCMS } from "@lib/constants/contentful";
import { pluralizeHelper, useMicrocopy } from "@lib/contentful/microcopy/MicrocopyContext";
import { Attributes } from "@lib/enums/ProductAttributes";
import { getBackgroundColorValue, getProductAttributeValue } from "@lib/utils/attributesUtils";
import { useMemo } from "react";
import { useQuery } from "urql";
import { gottenConfig } from "../config/site/site-config";
import type {
    GetMultipleProductsQuery,
    RawProductAttribute,
} from "../graphql/generated/components";
import { GetMultipleProductsDocument } from "../graphql/generated/components";
import { getPDPSlug } from "../lib/utils/getPDPSlug";

interface useListOfProductsQueryProps {
    products: string[];
    locale: string;
    keepOrder?: boolean;
}

const channelId = process.env.NEXT_PUBLIC_CHANNEL_ID;

export const useListOfProductsQuery = ({
    products,
    locale,
    keepOrder,
}: useListOfProductsQueryProps) => {
    const { currency, country } = gottenConfig;

    const [{ fetching, data }] = useQuery<GetMultipleProductsQuery>({
        query: GetMultipleProductsDocument,
        variables: {
            locale,
            channelId,
            country,
            currency,
            skus: products,
        },
    });

    const { get: getMicrocopy } = useMicrocopy();

    const memoizedData = useMemo(() => {
        if (fetching || !data?.products?.results || !data?.products?.results.length) {
            return null;
        }

        const parsedProducts = data?.products?.results?.map?.((item) => {
            const current = item?.masterData?.current;
            if (!current) {
                return null;
            }
            const masterV = current?.masterVariant;
            const variant = current?.variants?.[0];
            if (!variant) {
                //unpublished product. can also get published status in query, later fix
                return null;
            }

            const priceData = variant?.price;

            const { attributesRaw, images } = masterV || {};

            const colorCode = getProductAttributeValue(
                attributesRaw as RawProductAttribute[],
                Attributes.colorCode
            );

            const articleNumber = getProductAttributeValue(
                attributesRaw as RawProductAttribute[],
                Attributes.articleNumber
            );

            const dominantColor = getProductAttributeValue(
                attributesRaw as RawProductAttribute[],
                Attributes.dominantColor,
                locale
            );

            const collectionCategoryCode = getProductAttributeValue(
                attributesRaw as RawProductAttribute[],
                Attributes.collectionCategoryCode
            );

            const productColors = getProductAttributeValue(
                attributesRaw as RawProductAttribute[],
                Attributes.productColors
            );

            const image = images && images.length > 0 ? images[0] : null;

            return {
                // User-defined unique identifier of the Product.
                key: item?.key,
                objectID: variant?.sku,
                productName: current?.name,
                priceWithoutDiscount: priceData?.discounted?.value,
                priceWithDiscount: priceData?.value,
                url: getPDPSlug(current.name, articleNumber, colorCode, locale)?.slug,
                image,
                colorCode,
                articleNumber,
                dominantColor,
                collectionCategoryCode,
                locale,
                productColors: Array.isArray(productColors)
                    ? productColors?.map((it) => ({
                          priceWithDiscount: it.priceRaw,
                          priceWithoutDiscount: it.listPriceRaw,
                          image: it.image,
                          imageLabel: it.imageLabel,
                          objectID: it.objectID,
                          colorCode: it.colorCode,
                          altTagPattern: `${current?.name} - ${it.dominantColor}`,
                          imageBackgroundColor: it.imageBackgroundColor,
                          discountPercentage: it.discountPercentage,
                          hasDiscount: it.hasDiscount,
                      }))
                    : [],
                imageBackgroundColor: getBackgroundColorValue(
                    attributesRaw as RawProductAttribute[]
                ),
                colorLabel: getMicrocopy(CommonCMS.global, CommonCMS.colour, {
                    pluralize: pluralizeHelper(productColors?.length + 1),
                    replacements: {
                        numberOfColors: productColors?.length,
                    },
                }),
            };
        });

        // Sort the products in the same order as the products array
        if (keepOrder) {
            parsedProducts.sort((a, b) => {
                return products.indexOf(a?.key) - products.indexOf(b?.key);
            });
        }

        return parsedProducts;
    }, [data, fetching, keepOrder, locale, products]);

    if (!memoizedData) {
        return null;
    }

    return memoizedData;
};
